export default class RelyToShain {
  relyCode: string;
  incode: string;
  isValid: string;

  constructor(relyCode = "", incode = "", isValid = "") {
    this.relyCode = relyCode;
    this.incode = incode;
    this.isValid = isValid;
  }
}
